import { useEffect, useState } from "react";
import { useAuth } from "@workos-inc/authkit-react";

const persistUser = async ({ getAccessToken, setAuthData, user }) => {
  const accessToken = await getAccessToken();

  const userWithAccessToken = { ...user, accessToken };

  localStorage.setItem(`authData`, JSON.stringify(userWithAccessToken));
  setAuthData(userWithAccessToken);
};

export const AuthenticatedApp = ({ children }) => {
  const [authData, setAuthData] = useState(
    JSON.parse(localStorage.getItem(`authData`))
  );
  const { user, isLoading, signIn, getAccessToken } = useAuth();

  useEffect(() => {
    if (!isLoading && !user && !authData) {
      signIn();
    }
  }, [authData, isLoading, signIn, user]);

  useEffect(() => {
    if (!isLoading && user && !authData) {
      persistUser({ getAccessToken, setAuthData, user });
    }
  }, [authData, getAccessToken, isLoading, user]);

  if (isLoading || !authData) {
    return null;
  }

  return children;
};
