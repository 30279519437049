import { TakebackWithTonic } from "./components/TakebackWithTonic";
import { TakebackProvider } from "./context/TakebackContext";
import { useUser } from "./hooks/useUser";
import React from "react";

const Welcome = () => {
  const { email } = useUser();

  return <p>Welcome {email}</p>;
};

function App() {
  return (
    <TakebackProvider
      clientId="acme"
      apiEndpoint={process.env.REACT_APP_API || "http://localhost:8788/api"}
    >
      <div className="min-h-screen flex flex-col gap-4 items-center justify-center bg-gray-50">
        <Welcome />
        <TakebackWithTonic />
        <TakebackWithTonic
          takeback={{
            id: "123",
            attributes: {
              type: "refill",
              quantity: 5,
            },
          }}
        />
      </div>
    </TakebackProvider>
  );
}

export default App;
