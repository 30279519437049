import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTakebackContext } from "../context/TakebackContext";
import { useUser } from "../hooks/useUser";
import { gql } from "graphql-tag";

const CREATE_TAKEBACK = gql`
  mutation CreateTakeback($input: TakebackInput!) {
    createTakeback(input: $input) {
      success
      message
      eventId
      queued
    }
  }
`;

const UPDATE_TAKEBACK = gql`
  mutation UpdateTakeback($id: String!, $input: TakebackInput!) {
    updateTakeback(id: $id, input: $input) {
      success
      message
      eventId
      queued
    }
  }
`;

export const useTakebackForm = (existingTakeback = null) => {
  const { clientId, apiEndpoint } = useTakebackContext();
  const { accessToken } = useUser();

  const [formData, setFormData] = useState({
    id: existingTakeback?.id || uuidv4(),
    type: existingTakeback?.attributes?.type || "",
    productId: existingTakeback?.attributes?.productId || "",
    quantity: existingTakeback?.attributes?.quantity || 0,
    unit: existingTakeback?.attributes?.unit || "",
    manufacturer: existingTakeback?.attributes?.manufacturer || "",
    value: existingTakeback?.attributes?.value || "",
    currency: existingTakeback?.attributes?.currency || "",
    cost: existingTakeback?.attributes?.cost || "",
  });

  const isUpdate = Boolean(existingTakeback);
  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]:
        name === "quantity" || name === "value" || name === "cost"
          ? value === ""
            ? ""
            : Number(value)
          : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ loading: true, success: false, error: null });

    try {
      const timestamp = Math.floor(Date.now() / 1000);
      const statuses = [
        {
          status: "initiated",
          updated_at: new Date().toISOString(),
          secondary_status: "Takeback initiated via web form",
        },
      ];

      const takebackInput = {
        id: formData.id || uuidv4(),
        type_id: 1, // You might want to make this configurable
        timestamp: timestamp,
        source: "web-client",
        created_at: timestamp,
        schema_version: "1.0",
        type: formData.type,
        data: JSON.stringify({
          clientId,
          productId: formData.productId,
          quantity: Number(formData.quantity) || 0,
          unit: formData.unit,
          manufacturer: formData.manufacturer,
          value: formData.value ? Number(formData.value) : undefined,
          currency: formData.currency || undefined,
          cost: formData.cost ? Number(formData.cost) : undefined,
        }),
        statuses, // Pass statuses separately
      };

      const query = isUpdate ? UPDATE_TAKEBACK : CREATE_TAKEBACK;
      const variables = isUpdate
        ? { id: formData.id, input: takebackInput }
        : { input: takebackInput };

      const response = await fetch(`${apiEndpoint}/graphql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-client-id": clientId,
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          query: query.loc.source.body,
          variables,
        }),
      });

      const result = await response.json();

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }

      const operationResult = isUpdate
        ? result.data.updateTakeback
        : result.data.createTakeback;

      if (!operationResult.success) {
        throw new Error(operationResult.message || "Operation failed");
      }

      setStatus({ loading: false, success: true, error: null });

      if (!isUpdate) {
        // Only reset form if it's a create operation
        setFormData({
          id: "",
          type: "",
          productId: "",
          quantity: 0,
          unit: "",
          manufacturer: "",
          value: "",
          currency: "",
          cost: "",
        });
      }
    } catch (error) {
      console.error("Submission error:", error);
      setStatus({
        loading: false,
        success: false,
        error: error.message || "Failed to create takeback event",
      });
    }
  };

  return {
    formData,
    handleChange,
    handleSubmit,
    status,
  };
};
